import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import { recentlyTours, tours } from '~/data/tours';

import ViewsByDevices from './Components/ViewsByDevices';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
}));

function InfoCard({ className = '', title, value }) {
  const classes = useStyles();

  return (
    <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
      <div
        className="w-full bg-white flex flex-col justify-center rounded-lg"
        style={{
          padding: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
      >
        {title}
        <span className="font-bold text-2xl">{value}</span>
      </div>
    </div>
  );
}

const TourOverviewView = () => {
  const { tourId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const tour = tours.find(t => String(t.id) === tourId);

  if (!tour) {
    return null;
  }
  //   const companyName = useSelector(state => state.auth.user.company);
  //   const locations = useAsync(() => getAllLocations(companyName), [companyName]);
  //   const companyId = parseInt(id, 10);

  //   useEffect(() => {
  //     if (!companyId) {
  //       navigate('/companies');
  //     }
  //   }, [companyId, navigate]);

  //   if (locations.loading) {
  //     return null;
  //   }

  //   const companyLocations = locations.value.filter(
  //     l => l.company_name === companyName,
  //   );

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NavLink to="/overview" className={classes.backIcon} />
          <div>
            <div style={{ fontSize: '1.5rem' }}>{tour.title}</div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Tour Analytics
            </div>
          </div>
        </div>
      </Header>

      <div className="w-full flex flex-wrap" style={{ marginTop: '26px' }}>
        <InfoCard title="Total Visits" value="452" />
        <InfoCard
          className="pl-3"
          title="Average Visits Per Day"
          value="19.65"
        />
        <InfoCard
          className="pl-3"
          title="Average Visits Per Month"
          value="452"
        />
        <InfoCard className="pl-3" title="Total Duration" value="4h 2m 52s" />
        <InfoCard className="pl-3" title="Average Visit Duration" value="32s" />
        <InfoCard
          className="pl-3"
          title="Total Custom Button Clicks"
          value="50"
        />
        <InfoCard className="pl-3" title="Conversion" value="50" />
      </div>

      <div
        className="w-full flex bg-white mb-2"
        style={{ borderRadius: '10px', height: '273px' }}
      />
      <div
        className="w-full flex bg-white mb-4"
        style={{ borderRadius: '10px', height: '273px' }}
      />

      <div className="w-full flex">
        <div className="w-2/3 pr-4">
          <ViewsByDevices />
        </div>
        <div
          className="w-1/3 bg-white flex overflow-hidden"
          style={{
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="w-1/2 h-full"
            style={{ padding: '10px', height: '110px' }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: '0.6875rem',
                color: '#6C6C6C',
              }}
            >
              Top Point
            </div>
            <div style={{ fontWeight: 700, fontSize: '1.125rem' }}>
              Point #2
            </div>
          </div>
          <img className="w-1/2 h-full" src={tour.image} alt="" />
        </div>
      </div>
    </Page>
  );
};

export default TourOverviewView;
