import * as constants from './authConstants';

export function authLogin(user) {
  return dispatch => {
    dispatch({ type: constants.AUTH_LOGIN, payload: { user } });
  };
}

export function authLogout() {
  return dispatch => {
    dispatch({ type: constants.AUTH_LOGOUT });
  };
}
