import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
  },
  headerProfile: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
  },
}));

const Header = ({ children }) => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);

  if (!user) return null;

  const role = user.role.slice(0, 1) + user.role.slice(1).toLowerCase();

  return (
    <header className={classes.header}>
      <div>{children}</div>

      <div className={classes.headerProfile}>
        <div style={{ textAlign: 'right' }}>
          <div>{role}</div>
          <div style={{ fontWeight: 400 }}>{user.email}</div>
        </div>
        <img
          alt="Profile"
          style={{ width: '20px', height: '23px', marginLeft: '20px' }}
          src="/static/icons/profile.svg"
        />
      </div>
    </header>
  );
};

export default Header;
