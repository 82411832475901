import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '241px',
    background: '#fff',
    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15))',
    display: 'flex',
    flexDirection: 'column',
    color: '#333',
  },
  number: {
    letterSpacing: '0.13em',
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  prop: {
    fontWeight: 400,
    color: '#616161',
    fontSize: '0.625rem',
  },
  image: {
    width: '100%',
    height: '164px',
    boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.5)',
    objectFit: 'cover',
  },
  goToTourIcon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    backgroundImage: 'url(/static/icons/go.svg)',

    '&:hover': {
      backgroundImage: 'url(/static/icons/go-active.svg)',
    },
  },
}));

const BASE_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT;

function TourCard({ tour }) {
  const classes = useStyles();

  const mainLocation =
    tour.locations.find(l => l.description.IS_MAIN) || tour.locations[0];

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={`${BASE_URL}/${mainLocation.description.PREVIEW_IMAGE}`}
        alt=""
      />
      <div
        style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              fontSize: '0,875rem',
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            {tour.multilocationName}
          </div>

          <NavLink
            to={`/overview/${mainLocation.id}`}
            className={classes.goToTourIcon}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div className={classes.number}>{10}</div>
            <div className={classes.prop}>visits</div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <div className={classes.number}>{50}</div>
            <div className={classes.prop}>conversion</div>
          </div>
        </div>
      </div>
    </div>
  );
}

TourCard.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default TourCard;
