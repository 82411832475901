import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  // useTheme,
  makeStyles,
  // colors,
} from '@material-ui/core';
import Tooltip from './Tooltip';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {},
}));

function Chart({
  className,
  height,
  as: ChartType,
  title,
  datasets,
  labels,
  customTooltip,
}) {
  const classes = useStyles();
  // const theme = useTheme();
  console.log('datasets', datasets);
  const [tooltip, setTooltip] = useState({
    top: 0,
    left: 0,
    title: '',
    body: [],
    align: 'center',
    show: false,
  });

  // const data = {
  //   datasets: [
  //     {
  //       backgroundColor: colors.blue[500],
  //       data: [18, 5, 19, 27, 29, 19, 20],
  //       label: 'This year',
  //     },
  //     {
  //       backgroundColor: colors.grey[200],
  //       data: [11, 20, 12, 29, 30, 25, 13],
  //       label: 'Last year',
  //     },
  //   ],
  //   labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
  // };

  // const options = {
  //   animation: false,
  //   cornerRadius: 20,
  //   layout: { padding: 0 },
  //   legend: { display: false },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   scales: {
  //     xAxes: [
  //       {
  //         barThickness: 12,
  //         maxBarThickness: 10,
  //         barPercentage: 0.5,
  //         categoryPercentage: 0.5,
  //         ticks: {
  //           fontColor: theme.palette.text.secondary,
  //         },
  //         gridLines: {
  //           display: false,
  //           drawBorder: false,
  //         },
  //       },
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           fontColor: theme.palette.text.secondary,
  //           beginAtZero: true,
  //           min: 0,
  //         },
  //         gridLines: {
  //           borderDash: [2],
  //           borderDashOffset: [2],
  //           color: theme.palette.divider,
  //           drawBorder: false,
  //           zeroLineBorderDash: [2],
  //           zeroLineBorderDashOffset: [2],
  //           zeroLineColor: theme.palette.divider,
  //         },
  //       },
  //     ],
  //   },
  //   tooltips: {
  //     backgroundColor: theme.palette.background.default,
  //     bodyFontColor: theme.palette.text.secondary,
  //     borderColor: theme.palette.divider,
  //     borderWidth: 1,
  //     enabled: true,
  //     footerFontColor: theme.palette.text.secondary,
  //     intersect: false,
  //     mode: 'index',
  //     titleFontColor: theme.palette.text.primary,
  //   },
  // };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    onHover: () => setTooltip({ ...tooltip, show: false }),
    tooltips: {
      intersect: false,
      mode: 'nearest',
      enabled: false,
      custom: params => {
        setTooltip({
          top: params.caretY,
          left: params.caretX,
          title: params.title[0],
          body: params.body[0].lines,
          align: params.xAlign,
          show: params.opacity > 0,
        });
      },
    },
  };

  return (
    <React.Fragment>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          // action={
          //   <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
          //     Last 7 days
          //   </Button>
          // }
          title={title}
        />
        <Divider />
        <CardContent>
          <Box height={height} position="relative">
            <ChartType data={{ datasets, labels }} options={options} />
            <Tooltip config={tooltip}>
              {customTooltip ? (
                customTooltip(tooltip)
              ) : (
                <React.Fragment>
                  <Typography variant="h5">{tooltip.title}</Typography>
                  <Typography variant="body">{tooltip.body}</Typography>
                </React.Fragment>
              )}
            </Tooltip>
          </Box>
        </CardContent>
        {/* <Divider /> */}
        {/* <Box display="flex" justifyContent="flex-end" p={2}> */}
        {/*   <Button */}
        {/*     color="primary" */}
        {/*     endIcon={<ArrowRightIcon />} */}
        {/*     size="small" */}
        {/*     variant="text" */}
        {/*   > */}
        {/*     Overview */}
        {/*   </Button> */}
        {/* </Box> */}
      </Card>
    </React.Fragment>
  );
}

Chart.propTypes = {
  className: PropTypes.string,
  as: PropTypes.func,
  height: PropTypes.number,
  customTooltip: PropTypes.func,
};

Chart.defaultProps = {
  className: '',
  as: Line,
  height: 200,
  customTooltip: null,
};

export default Chart;
