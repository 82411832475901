import React from 'react';
import {
  Container,
  Box,
  Grid,
  colors,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { Bar, Line } from 'react-chartjs-2';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import { getStats, getAllStats, getCompanyLocationStats } from '~/services/api';
import { menuItems, authRoles } from '~/store/auth/authConstants';
import { eventTypes } from '~/views/private/location/LocationView/components/Analytics/constants';

import Chart from '../location/LocationView/components/Analytics/components/Chart';

import { recentlyTours, mostPopularTours } from '~/data/tours';

import ViewsByDevices from './Components/ViewsByDevices';
import CopyInput from './Components/CopyInput';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  popularTourCard: {
    padding: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
}));

function InfoCard({ className = '', title, value }) {
  const classes = useStyles();

  return (
    <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
      <div
        className="w-full bg-white flex flex-col justify-center rounded-lg"
        style={{
          padding: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
      >
        {title}
        <span className="font-bold text-2xl">{value}</span>
      </div>
    </div>
  );
}

function millisecondsToHuman(duration) {
  const ms = parseInt((duration % 1000) / 100);
  let seconds = parseInt((duration / 1000) % 60);
  let minutes = parseInt((duration / (1000 * 60)) % 60);
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 150);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const gradientBar = ctx.createLinearGradient(0, 0, 0, 150);
gradientBar.addColorStop(0, 'rgba(54,166,253,1)');
gradientBar.addColorStop(1, 'rgba(54,166,253,0.49)');

const OverviewView = () => {
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);
  // const allStats = useAsync(getAllStats, []);

  const stats = useAsync(() => getCompanyLocationStats('google'));

  // console.log({stats})

  const pointDurationStats = useAsync(
    () => getStats(1, eventTypes.POINT_DURATION),
    [],
  );

  const visitStats = useAsync(() => getStats(1, eventTypes.VISIT), []);

  const isAdmin = user.role === authRoles.ADMIN;

  if (stats.loading || pointDurationStats.loading) {
    return null;
  }

  // const pointDurationStats = {
  //   // value: allStats.value.topPoints,
  //   value: [],
  // };

  // const pointStats = useAsync(
  //   () => getStats(location.id, eventTypes.POINT),
  //   [],
  // );

  //   const companyName = useSelector(state => state.auth.user.company);
  //   const locations = useAsync(() => getAllLocations(companyName), [companyName]);
  //   const companyId = parseInt(id, 10);

  //   useEffect(() => {
  //     if (!companyId) {
  //       navigate('/companies');
  //     }
  //   }, [companyId, navigate]);

  //   if (locations.loading) {
  //     return null;
  //   }

  //   const companyLocations = locations.value.filter(
  //     l => l.company_name === companyName,
  //   );

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <div style={{ fontSize: '1.5rem' }}>OVERWIEW</div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Hello, Admin! Take a look at general analytic
            </div>
          </div>
        </div>
      </Header>

      <div className="w-full flex" style={{ marginTop: '26px' }}>
        <div className="w-5/12 pr-4 flex flex-col">
          <CopyInput value="https://dashboard.theviewvr.com.html?locationName=Townhouse%2024520..." />
          <div className="w-full flex flex-wrap justify-between mb-1">
            <InfoCard
              title="Total Custom Button Clicks "
              value={stats.value.totalCustomButtonClicks}
            />
            <InfoCard className="pl-3" title="Conversion " value="50" />
          </div>

          <div
            className="w-full bg-white"
            style={{
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <div
              className="flex font-bold"
              style={{ color: '#6C6C6C', marginBottom: '22px' }}
            >
              <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
                Most Popular Tours
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                Visits
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                View Duration
              </div>
            </div>

            {mostPopularTours.map(tour => {
              return (
                <NavLink
                  to={`/overview/${tour.id}`}
                  key={tour.id}
                  className={clsx('flex items-center', classes.popularTourCard)}
                  style={{ marginBottom: '10px' }}
                >
                  <div className="flex w-1/2 font-bold uppercase items-center">
                    <img
                      className="w-1/2 flex-shrink-0"
                      style={{
                        marginRight: '20px',
                        height: '64px',
                        borderRadius: '10px',
                        flexShrink: 0,
                      }}
                      src={tour.image}
                      alt=""
                    />
                    <div>{tour.title}</div>
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {tour.visits}
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {tour.conversion}
                  </div>
                </NavLink>
              );
            })}
          </div>

          <div
            className="w-full bg-white mt-4 flex-1"
            style={{
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
              Payment status
            </div>

            <div
              className="flex font-bold"
              style={{
                color: '#6C6C6C',
                marginTop: '10px',
                marginBottom: '22px',
              }}
            >
              <div className="w-1/4" style={{ fontSize: '0.6875rem' }}>
                Tour name
              </div>
              <div className="w-1/4 pl-4" style={{ fontSize: '0.6875rem' }}>
                Status
              </div>
              <div className="w-1/2 flex">
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Start Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Due Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Type
                </div>
              </div>
            </div>

            {mostPopularTours.map((tour, idx) => {
              return (
                <div
                  key={tour.id}
                  className="flex"
                  style={{
                    color: '#6C6C6C',
                    marginTop: '10px',
                    marginBottom: '22px',
                  }}
                >
                  <div
                    className="w-1/4 font-bold"
                    style={{ color: '#333', fontSize: '0.6875rem' }}
                  >
                    {idx + 1} {tour.title}
                  </div>
                  <div
                    className="w-1/4 pl-4 flex justify-start items-center font-bold"
                    style={{ fontSize: '0.6875rem' }}
                  >
                    {tour.status === 'payed' ? (
                      <div style={{ color: '#29CC97' }}>Payed</div>
                    ) : (
                      <div style={{ color: '#E74C3C' }}>Unpaid</div>
                    )}

                    <img
                      style={{ marginLeft: '10px' }}
                      width="12"
                      height="12"
                      src={`/static/icons/${
                        tour.status === 'payed' ? 'payed' : 'unpaid'
                      }.svg`}
                      alt={tour.status}
                    />
                  </div>
                  <div className="w-1/2 flex">
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      01.01.20
                    </div>
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      01.01.21
                    </div>
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      Annually
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-7/12 w-full flex flex-col">
          <div className="w-full flex flex-wrap justify-between mb-1">
            <InfoCard
              title="Total Visits"
              value={stats.value.totalVisits || 0}
            />
            <InfoCard
              className="pl-3"
              title="Average Visits Per Day"
              value={stats.value.avgVisitsPerDay || 0}
            />
            <InfoCard
              className="pl-3"
              title="Average Visits Per Month"
              value={stats.value.avgVisitsPerMonth || 0}
            />
            <InfoCard
              className="pl-3"
              title="Total Duration"
              value={millisecondsToHuman(stats.value.totalDuration * 1000)}
            />
            <InfoCard
              className="pl-3"
              title="Average Visit Duration"
              value={millisecondsToHuman(
                stats.value.avgVisitDuration * 1000 || 0,
              )}
            />
          </div>

          <div
            className="w-full bg-white"
            style={{
              height: '272px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Chart
              as={Line}
              title="Visits"
              datasets={[
                {
                  // fill:'start',
                  backgroundColor: gradient,
                  lineTension: 0.01,
                  borderColor: '#36A6FD',
                  pointRadius: 0,
                  borderWidth: 1,
                  // backgroundColor: colors.blue[500],
                  data: visitStats.value.map(item => item.eventsCount),
                  label: ' visits',
                },
              ]}
              labels={visitStats.value.map(item => item.eventDate)}
            />
          </div>
          <div
            className="w-full bg-white mb-4"
            style={{
              height: '272px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Chart
              as={Bar}
              title="View Duration"
              datasets={[
                {
                  backgroundColor: gradientBar,
                  data: pointDurationStats.value.map(item => item.duration),
                  label: ' duration',
                },
              ]}
              labels={pointDurationStats.value.map(
                item => `Point #${item.pointId}`,
              )}
            />
          </div>

          <div>
            <ViewsByDevices />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default OverviewView;
