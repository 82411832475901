import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

import TourCard from '../components/TourCard';
import { tours } from '~/data/tours';

import { buildLocationsForUser } from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
}));

const GalleryView = () => {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);

  const locations = useAsync(() => buildLocationsForUser(user.company), [
    user.company,
  ]);

  if (locations.loading) return null;

  const multilocations = locations.value.filter(x => x.locations.length > 1);

  console.log({ multilocations });

  return (
    <Page className={classes.root} title="Gallery">
      <Header>
        <div style={{ fontSize: '1.5rem' }}>All your tours in one place!</div>
        <div style={{ fontSize: '0.875rem', fontWeight: 400 }}>
          Choose any tour and check analytics
        </div>
      </Header>

      {/* <div style={{ paddingTop: '20px', display: 'flex', flexWrap: 'wrap' }}> */}
      <Grid
        container
        spacing={4}
        style={{ paddingTop: '30px', paddingRight: '40px' }}
      >
        {multilocations.map(multilocation => (
          <Grid
            key={multilocation.multilocationName}
            item
            lg={4}
            md={6}
            xs={12}
          >
            <TourCard tour={multilocation} />
          </Grid>
        ))}
      </Grid>
      {/* </div> */}
    </Page>
  );
};

export default GalleryView;
