import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { authLogin } from '~/store/actions';
import { authRoles } from '~/store/auth/authConstants';
import Page from '~/components/shared/Page';
import { loginWithEmail, loginWithSession } from '~/services/magic';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function roleToAuthRole(role) {
  return role === 'admin' || role === 'superadmin'
    ? authRoles.ADMIN
    : authRoles.USER;
}

function MagicAuth({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const proceedAuth = useCallback(
    result => {
      if (result) {
        dispatch(
          authLogin({
            email: result.email,
            role: roleToAuthRole(result.role),
            company: result.companyName,
          }),
        );
        setIsLoggedIn(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    loginWithSession()
      .then(proceedAuth)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [proceedAuth]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/overview', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isLoggedIn) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const invalidate = actions =>
    actions.setFieldError('email', 'Email is incorrect');

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const result = await loginWithEmail(values.email);
      if (result) {
        proceedAuth(result);
      } else {
        invalidate(actions);
      }
    } catch (e) {
      console.error(e);
      invalidate(actions);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
}

MagicAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MagicAuth;
