import * as constants from './authConstants';

const initialState = {
  isLoggedIn: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case constants.AUTH_LOGIN:
      return {
        ...action.payload,
        isLoggedIn: true,
      };
    case constants.AUTH_LOGOUT:
      return {
        ...initialState,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
