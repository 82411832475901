import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  deviceItem: {
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      color: '#36A6FD',
    },

    '&>.icon': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      width: '20%',
    },

    '& .mobile-icon': {
      backgroundImage: 'url(/static/icons/mobile-device.svg)',
    },
    '& .desktop-icon': {
      backgroundImage: 'url(/static/icons/desktop-device.svg)',
    },
    '& .tablet-icon': {
      backgroundImage: 'url(/static/icons/tablet-device.svg)',
    },

    '&:hover .mobile-icon': {
      backgroundImage: 'url(/static/icons/mobile-device-active.svg)',
    },
    '&:hover .desktop-icon': {
      backgroundImage: 'url(/static/icons/desktop-device-active.svg)',
    },
    '&:hover .tablet-icon': {
      backgroundImage: 'url(/static/icons/tablet-device-active.svg)',
    },
  },
}));

export default function () {
  const classes = useStyles();

  return (
    <div
      className="h-full w-full bg-white"
      style={{
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
      }}
    >
      <span className="font-bold text-xs">Views by devices</span>

      <div className="w-full flex flex-wrap" style={{ marginTop: '15px' }}>
        <div className={clsx('w-1/2 flex', classes.deviceItem)}>
          <div className="icon mobile-icon" style={{ marginRight: '10px' }} />
          <div>
            <div className="text-2xl">50%</div>
            <div className="text-xs">mobiles</div>
          </div>
        </div>

        <div className={clsx('w-1/2 flex', classes.deviceItem)}>
          <div className="icon desktop-icon" style={{ marginRight: '10px' }} />
          <div>
            <div className="text-2xl">35%</div>
            <div className="text-xs">desktops</div>
          </div>
        </div>

        {/* <div className={clsx('w-1/3 flex', classes.deviceItem)}>
          <div className="icon tablet-icon" style={{ marginRight: '10px' }} />
          <div>
            <div className="text-2xl">15%</div>
            <div className="text-xs">tablets</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
