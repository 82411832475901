import { Magic } from 'magic-sdk';
import { loginCompanyByToken } from './api';

const magic = new Magic(process.env.MAGIC_API_KEY || '');

export async function loginWithSession() {
  const ok = await magic.user.isLoggedIn();
  if (!ok) {
    return false;
  }

  const token = await magic.user.getIdToken();
  return loginCompanyByToken(token);
}

export async function loginWithEmail(email = '') {
  const token = await magic.auth.loginWithMagicLink({ email, showUI: true });
  return loginCompanyByToken(token);
}

export async function logout() {
  await magic.user.logout();
}
