// const BASE_URL = process.env.API_ENDPOINT || '';
const BASE_URL = process.env.SNOWPACK_PUBLIC_API_ENDPOINT || '';

function buildCompanyMultilocations(companyName, locationsMap) {
  const locations = locationsMap[companyName] || [];

  return locations.reduce((list, location) => {
    const name = location.description.LIST_ID
      ? location.description.LIST_ID
      : location.name;

    if (list.some(x => x.multilocationName === name)) {
      const idx = list.findIndex(x => x.multilocationName === name);

      list[idx].locations.push(location);
    } else {
      list.push({
        multilocationName: name,
        id: location.id,
        locations: [location],
      });
    }

    return list;
  }, []);
}

export const buildLocationsForAdmin = async function () {
  const companies = await fetch(`${BASE_URL}/multiLocations`)
    .then(res => res.json())
    .then(json => json.result)
    .then(mls => {
      return mls.map(ml => ml.companyName);
    });

  const locationsMap = {};

  for (const company of companies) {
    locationsMap[company] = await getAllLocations(company);
  }

  const list = companies.reduce((list, companyName) => {
    return [
      ...list,
      {
        companyName,
        multilocations: buildCompanyMultilocations(companyName, locationsMap),
      },
    ];
  }, []);

  return list;
};

export const buildLocationsForUser = async function (companyName) {
  companyName = companyName || 'google';
  return buildCompanyMultilocations(companyName, {
    [companyName]: await getAllLocations(companyName),
  });
};

export async function getAllLocations(companyName = 'Google') {
  companyName = companyName || 'Google';

  const res = await fetch(
    `${BASE_URL}/getLocationsOfCompany?companyName=${companyName}`,
  );
  if (!res.ok) {
    throw new Error('getAllLocations request error');
  }
  const { result } = await res.json();
  return result.map(location => {
    return {
      ...location,
      company_name: companyName,
    };
  });
}

export async function getAllMultiLocations() {
  const res = await fetch(`${BASE_URL}/multiLocations`);
  if (!res.ok) {
    throw new Error('getAllMultiLocations request error');
  }
  const { result } = await res.json();

  return result;
}

export async function getAllStats() {
  const res = await fetch(`${BASE_URL}/stats/all`);

  if (!res.ok) {
    throw new Error('getAllStats request error');
  }
  const { result } = await res.json();

  return result;
}

export async function getLocation(id) {
  const res = await fetch(`${BASE_URL}/getLocationById?locationId=${id}`);
  const body = await res.json();
  if (!res.ok || body.result.length === undefined) {
    throw new Error('getLocation request error');
  }
  return body.result[0];
}

export async function getCompanyLocationStats(companyName) {
  const res = await fetch(
    `${BASE_URL}/stats/companyLocation?companyName=${companyName}`,
  );

  const body = await res.json();
  if (!res.ok || !body.result) {
    throw new Error('getCompanyLocationStats request error');
  }

  return body.result;
}

export async function getStats(id, type, multitour) {
  const from = 0;
  const to = 2608829740392;
  const res = await fetch(
    `${BASE_URL}/getStat?locationId=${id}&statId=${type}&dateStart=${from}&dateEnd=${to}&multiTourName=${multitour}`,
  );
  const body = await res.json();
  if (!res.ok || !body.result || body.result.message === undefined) {
    throw new Error('getStats request error');
  }
  return body.result.message;
}

export async function getSummaryStats(id) {
  const res = await fetch(`${BASE_URL}/getLocationStats?locationId=${id}`);
  const body = await res.json();
  if (!res.ok || !body.result || body.result.message === undefined) {
    throw new Error('getSummaryStats request error');
  }

  console.log(body.result.message);
  return body.result.message;
}

async function tryFetch(route, options) {
  const res = await fetch(BASE_URL + route, options);
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json();
}

export async function loginCompanyByToken(token) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Access-Token': `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  };

  try {
    const data = await tryFetch('/login/client', req);
    return data.result;
  } catch (err) {
    console.error('Failed to log in', err);
    return null;
  }
}

globalThis.getSummaryStats = getSummaryStats;
