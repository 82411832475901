import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useAsync } from 'react-use';
import { Bar, Line } from 'react-chartjs-2';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getStats,
  getAllStats,
  getCompanyLocationStats,
  getLocation,
  getSummaryStats,
} from '~/services/api';

import { recentlyTours, tours } from '~/data/tours';

import ViewsByDevices from './Components/ViewsByDevices';

import Chart from '../location/LocationView/components/Analytics/components/Chart';
import { menuItems, authRoles } from '~/store/auth/authConstants';
import { eventTypes } from '~/views/private/location/LocationView/components/Analytics/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
}));

function InfoCard({ className = '', title, value }) {
  const classes = useStyles();

  return (
    <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
      <div
        className="w-full bg-white flex flex-col justify-center rounded-lg"
        style={{
          padding: '10px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        }}
      >
        {title}
        <span className="font-bold text-2xl">{value}</span>
      </div>
    </div>
  );
}

function millisecondsToHuman(duration) {
  const ms = parseInt((duration % 1000) / 100);
  let seconds = parseInt((duration / 1000) % 60);
  let minutes = parseInt((duration / (1000 * 60)) % 60);
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 150);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const gradientBar = ctx.createLinearGradient(0, 0, 0, 150);
gradientBar.addColorStop(0, 'rgba(54,166,253,1)');
gradientBar.addColorStop(1, 'rgba(54,166,253,0.49)');

const TourOverviewView = () => {
  const { tourId, locationId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  // const tour = tours.find(t => String(t.id) === tourId);
  const location = useAsync(() => getLocation(locationId), [locationId]);

  const summaryStats = useAsync(() => getSummaryStats(locationId), [
    locationId,
  ]);

  const pointDurationStats = useAsync(
    () => getStats(locationId, eventTypes.POINT_DURATION),
    [locationId],
  );

  const visitStats = useAsync(() => getStats(locationId, eventTypes.VISIT), [
    locationId,
  ]);
  const stats = useAsync(
    () => getCompanyLocationStats(location.value && location.value.name),
    [location.value],
  );

  if (location.loading) {
    return null;
  }

  if (!stats.value || !summaryStats.value) return null;

  if (!location.value) return null;

  console.log({ summaryStats });

  //   const companyName = useSelector(state => state.auth.user.company);
  //   const locations = useAsync(() => getAllLocations(companyName), [companyName]);
  //   const companyId = parseInt(id, 10);

  //   useEffect(() => {
  //     if (!companyId) {
  //       navigate('/companies');
  //     }
  //   }, [companyId, navigate]);

  //   if (locations.loading) {
  //     return null;
  //   }

  //   const companyLocations = locations.value.filter(
  //     l => l.company_name === companyName,
  //   );

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#6C6C6C' }}
        >
          <NavLink to="/overview" className={classes.backIcon} />
          <div>
            <div style={{ fontSize: '1.5rem' }}>
              {/* <NavLink to={`/overview/${tour.id}`}>{tour.title}</NavLink> /{' '} */}
              <span style={{ color: '#333' }}>{location.value.name}</span>
            </div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Location Analytics
            </div>
          </div>
        </div>
      </Header>

      <div className="w-full flex flex-wrap" style={{ marginTop: '26px' }}>
        <InfoCard
          title="Total Visits"
          value={summaryStats.value.totalVisits || 0}
        />
        <InfoCard
          className="pl-3"
          title="Average Visits Per Day"
          value={Number(summaryStats.value.avgVisitsPerDay || 0).toFixed(2)}
        />
        <InfoCard
          className="pl-3"
          title="Average Visits Per Month"
          value={Number(summaryStats.value.avgVisitsPerMonth || 0).toFixed(2)}
        />
        <InfoCard
          className="pl-3"
          title="Total Duration"
          value={millisecondsToHuman(summaryStats.value.totalDuration * 1000)}
        />
        <InfoCard
          className="pl-3"
          title="Average Visit Duration"
          value={millisecondsToHuman(
            summaryStats.value.avgVisitDuration * 1000 || 0,
          )}
        />
        <InfoCard
          className="pl-3"
          title="Total Custom Button Clicks"
          value={summaryStats.value.totalCustomButtonClicks}
        />
        <InfoCard className="pl-3" title="Conversion" value="50" />
      </div>

      <div
        className="w-full flex bg-white mb-2"
        style={{ borderRadius: '10px', height: '273px' }}
      >
        <Chart
          as={Line}
          title="Visits"
          datasets={[
            {
              // fill:'start',
              backgroundColor: gradient,
              lineTension: 0.01,
              borderColor: '#36A6FD',
              pointRadius: 0,
              borderWidth: 1,
              // backgroundColor: colors.blue[500],
              data: visitStats.value.map(item => item.eventsCount),
              label: ' visits',
            },
          ]}
          labels={visitStats.value.map(item => item.eventDate)}
        />
      </div>

      <div
        className="w-full flex bg-white mb-4"
        style={{ borderRadius: '10px', height: '273px' }}
      >
        <Chart
          as={Bar}
          title="View Duration"
          datasets={[
            {
              backgroundColor: gradientBar,
              data: pointDurationStats.value.map(item => item.duration),
              label: ' duration',
            },
          ]}
          labels={pointDurationStats.value.map(
            item => `Point #${item.pointId}`,
          )}
        />
      </div>

      <div className="w-full flex">
        <div className="w-2/3 pr-4">
          <ViewsByDevices />
        </div>
        <div
          className="w-1/3 bg-white flex overflow-hidden"
          style={{
            borderRadius: '10px',
            height: '110px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div className="w-1/2 h-full" style={{ padding: '10px' }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: '0.6875rem',
                color: '#6C6C6C',
              }}
            >
              Top Point
            </div>
            <div style={{ fontWeight: 700, fontSize: '1.125rem' }}>
              Point #2
            </div>
          </div>
          <img
            className="w-1/2 h-full"
            src={`${process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT}/${location.value.description.PREVIEW_IMAGE}`}
            alt=""
          />
        </div>
      </div>
    </Page>
  );
};

export default TourOverviewView;
