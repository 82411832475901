import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { logout } from '~/services/magic';
import NavBar from './components/NavBar';
import SortBar from './components/SortBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    padding: '32px 47px 27px 36px',
    // paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256,
    // },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function DashboardLayout() {
  const classes = useStyles();
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleLogOut = async () => {
    try {
      await logout();
      document.location.assign(window.location.href);
    } catch (err) {
      console.error(err);
    }
  };

  console.log(location);
  const showSideBar = ['/contacts', '/gallery', '/overview/'].some(path =>
    location.pathname.startsWith(path),
  );

  return (
    <div className={classes.root}>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      {showSideBar && <SortBar />}
      <div className={classes.wrapper}>
        {/* <header className={classes.header}></header> */}
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
