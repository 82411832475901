import React, { useEffect } from 'react';
import { useAsync } from 'react-use';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getAllLocations } from '~/services/api';
import Page from '~/components/shared/Page';
import LocationTable from '~/components/shared/LocationTable';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  list: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
  },
}));

const CompanyView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();
  const companyName = useSelector(state => state.auth.user.company);
  const locations = useAsync(() => getAllLocations(companyName), [companyName]);
  const companyId = parseInt(id, 10);

  useEffect(() => {
    if (!companyId) {
      navigate('/companies');
    }
  }, [companyId, navigate]);

  if (locations.loading) {
    return null;
  }

  const companyLocations = locations.value.filter(
    l => l.company_name === companyName,
  );

  return (
    <Page className={classes.root} title="Company Locations">
      <Container maxWidth={false}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => navigate('/companies')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="h4"
          >
            {companyName}
          </Typography>
        </Box>
        <Box className={classes.list}>
          <LocationTable locations={companyLocations} />
        </Box>
      </Container>
    </Page>
  );
};

export default CompanyView;
