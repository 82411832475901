import React, { useEffect } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import { TreeView, TreeItem } from '@material-ui/lab';
import {
  getAllLocations,
  getAllMultiLocations,
  buildLocationsForAdmin,
  buildLocationsForUser,
} from '~/services/api';
import { authRoles } from '~/store/auth/authConstants';

import { tours } from '~/data/tours';

const useStyles = makeStyles(theme => ({
  searchInput: {
    width: '100%',
    height: '29px',
    color: '#333',
    fontSize: '0.875rem',
    fontWeight: 400,
    padding: '5px 10px 5px 40px',
    border: 'none',
    outline: 'none',
    backgroundColor: '#F6F5F5',
    backgroundImage: 'url(/static/icons/search.svg)',
    backgroundPosition: '13px 7px',
    backgroundRepeat: 'no-repeat',
    border: '1px solid transparent',

    '&:focus': {
      backgroundImage: 'url(/static/icons/search-active.svg)',
      border: '1px solid #36A6FD',
    },
  },

  item: {
    margin: '2px 0',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#333',
    fontSize: '0.75rem',
    fontWeight: 400,
    '&:hover, &.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
    padding: '2px 4px',
    wordBreak: 'break-all',

    // '&:has( >a.active)': {background: 'rgba(54, 166, 253, 0.15) !important'},

    '& .icon[data-visible="false"]': { display: 'none' },

    '& .icon': {
      width: '10px',
      height: '10px',
      backgroundImage: 'url(/static/icons/open.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginLeft: 'auto',
      backgroundPosition: '50% 50%',
      marginLeft: '20px',
    },

    '&:hover .icon': {
      backgroundImage: 'url(/static/icons/open-active.svg)',
    },
  },

  hr: {
    width: '100%',
    height: '1px',
    background: 'rgba(229, 229, 229, 0.5)',
  },
  treeItem: {
    '& .MuiTreeItem-label': { padding: '0 !important' },
    '& .MuiTreeItem-iconContainer': { display: 'none !important' },
    '& .MuiTreeItem-label:hover': {
      background: 'rgba(54, 166, 253, 0.15) !important',
    },
    // '& .Mui-selected .MuiTreeItem-label': {background: 'rgba(54, 166, 253, 0.15) !important'},

    '& *[aria-expanded="true"] .icon': {
      backgroundImage: 'url(/static/icons/close.svg) !important',
    },
  },

  treeItemLocation: {
    display: 'block',
    width: '100%',
    // background: 'transparent !important',
    padding: '2px 4px',
    fontSize: '0.75rem',
    fontWeight: 400,

    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
}));

const SortBar = () => {
  const classes = useStyles();

  // const [company, setCompany] = useState(0);
  // user.company

  const user = useSelector(state => state.auth.user);

  const isAdmin = false; // user.role === authRoles.ADMIN;

  const locations = useAsync(() => buildLocationsForUser(user.company), [
    user.company,
  ]);

  console.log({ locations });

  // const allLocationsForAdmin = useAsync(buildLocationsForAdmin);

  if (locations.loading /* || allLocationsForAdmin.loading*/) {
    return null;
  }

  return (
    <Box
      style={{ overflowY: 'auto' }}
      minWidth="284px"
      flexShrink="0"
      padding="25px 20px"
      height="100%"
      display="flex"
      flexDirection="column"
      bgcolor="#fff"
      alignItems="center"
      color="#fff"
    >
      <input placeholder="Search" className={classes.searchInput} />
      <NavLink
        to="/gallery/recently"
        className={classes.item}
        style={{ margin: '12px 0' }}
      >
        <img
          style={{ width: '16px', height: '16px', marginRight: '30px' }}
          src="/static/icons/recently.svg"
        />
        Recently Viewed
      </NavLink>

      <div className={classes.hr} />

      <TreeView
        style={{ width: '100%', color: '#000', margin: '12px 0 6px 0' }}
      >
        <TreeItem
          className={classes.treeItem}
          style={{ background: 'transparent' }}
          nodeId="1"
          label={
            <div className={classes.item}>
              <img
                style={{ width: '16px', height: '16px', marginRight: '30px' }}
                src="/static/icons/favorites.svg"
              />
              <NavLink
                to="/gallery/favorite"
                style={{
                  fontWeight: 700,
                  fontSize: '0.875rem',
                  letterSpacing: '0.13em',
                }}
              >
                FAVORITES
              </NavLink>
              <div className="icon" data-visible />
            </div>
          }
        >
          <TreeItem
            nodeId={'2'}
            label={
              <NavLink
                to={`/overview/1/1`}
                className={classes.treeItemLocation}
              />
            }
          />
        </TreeItem>
      </TreeView>

      <div className={classes.hr} />
      {/* 
      {isAdmin && allLocationsForAdmin.value.map(
        ({ companyName, multilocations }, companyIdx) => {
          return (
            <TreeView
              key={companyName}
              style={{
                paddingTop: '40px',
                paddingLeft: '40px',
                width: '100%',
                color: '#000',
              }}
            >
              <TreeItem
                className={classes.treeItem}
                nodeId={String(Math.floor(Math.random()*100)+companyIdx + 1)}
                label={
                  <div className={classes.item}>
                    <img
                      style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '10px',
                      }}
                      src="/static/icons/folder.svg"
                    />
                    <NavLink
                      to={`/overview/${companyName}`}
                      style={{
                        fontWeight: 700,
                        fontSize: '0.875rem',
                        letterSpacing: '0.13em',
                        textTransform: 'uppercase',
                      }}
                    >
                      {companyName}
                    </NavLink>

                    <div
                      className="icon"
                      data-visible={multilocations.length > 0}
                    />
                  </div>
                }
              >
                {multilocations.map(
                  ({ multilocationName, locations }, multilocationIdx) => {
                    const isMultilocation = locations.length > 1;
                    return (
                      <TreeItem
                        key={multilocationName}
                        nodeId={String(
                          Math.floor(Math.random()*100) + (multilocationIdx + 1),
                        )}
                        label={
                          <div className={classes.item}>
                            {isMultilocation && (
                              <img
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  marginRight: '10px',
                                }}
                                src="/static/icons/folder.svg"
                              />
                            )}

                            {isMultilocation ? (
                              <NavLink
                                to={`/overview/${companyName}/${multilocationName}`}
                                style={{
                                  fontWeight: 700,
                                  fontSize: '0.875rem',
                                  letterSpacing: '0.13em',
                                  textTransform: 'uppercase',
                                }}
                              >
                                {multilocationName}
                              </NavLink>
                            ) : (
                              <NavLink
                                to={`/overview/${companyName}/${multilocationName}`}
                              >
                                {multilocationName}
                              </NavLink>
                            )}

                            <div
                              className="icon"
                              data-visible={
                                isMultilocation && locations.length > 0
                              }
                            />
                          </div>
                        }
                      >
                        {locations.length > 1 &&
                          locations.map((location, locationIdx) => {
                            return (
                              <TreeItem
                                key={location.name + ' ' + location.id}
                                nodeId={String(
                                  Math.floor(Math.random()*100)+(locationIdx + 1) * 1000 + locationIdx,
                                )}
                                label={
                                  <div className={classes.item}>
                                    <NavLink
                                      to={`/overview/${companyName}/${multilocationName}/${location.id}`}
                                      className={classes.treeItemLocation}
                                    >
                                      {location.name}
                                    </NavLink>
                                  </div>
                                }
                              />
                            );
                          })}
                      </TreeItem>
                    );
                  },
                )}
              </TreeItem>
            </TreeView>
          );
        },
      )} */}

      {!isAdmin &&
        locations.value.map(
          ({ multilocationName, locations, id }, multilocationIdx) => {
            const isMultilocation = locations.length > 1;
            return (
              <TreeView
                key={multilocationName}
                style={{
                  paddingTop: '40px',
                  paddingLeft: '40px',
                  width: '100%',
                  color: '#000',
                }}
              >
                <TreeItem
                  nodeId={String(multilocationIdx + 1 * 100)}
                  label={
                    <div className={classes.item}>
                      {isMultilocation && (
                        <img
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '10px',
                          }}
                          src="/static/icons/folder.svg"
                        />
                      )}

                      {isMultilocation ? (
                        <NavLink
                          to="#"
                          // to={`/overview/1/${multilocationName}`}
                          style={{
                            fontWeight: 700,
                            fontSize: '0.875rem',
                            letterSpacing: '0.13em',
                            textTransform: 'uppercase',
                          }}
                        >
                          {multilocationName}
                        </NavLink>
                      ) : (
                        <NavLink to={`/overview/${id}`}>
                          {locations[0].name}
                        </NavLink>
                      )}

                      <div
                        className="icon"
                        data-visible={isMultilocation && locations.length > 0}
                      />
                    </div>
                  }
                >
                  {locations.length > 1 &&
                    locations.map((location, locationIdx) => {
                      return (
                        <TreeItem
                          key={`${location.name} ${location.id}`}
                          nodeId={String((locationIdx + 1) * 1000)}
                          label={
                            <div className={classes.item}>
                              <NavLink
                                to={`/overview/${location.id}`}
                                className={classes.treeItemLocation}
                              >
                                {location.name}
                              </NavLink>
                            </div>
                          }
                        />
                      );
                    })}
                </TreeItem>
              </TreeView>
            );
          },
        )}
    </Box>
  );
};

export default SortBar;
