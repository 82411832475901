import React from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    color: '#333',
  },

  contactItem: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '40px',
  },
  contactItemNumber: {
    marginTop: '10px',
    color: '#A0A0A0',
  },
}));

const ContactsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Contacts">
      <Header>
        <div style={{ fontSize: '1.5rem' }}>Contact Us</div>
        <div style={{ fontSize: '0.875rem', fontWeight: 400 }}>
          Do you have a question?
        </div>
      </Header>

      <div style={{ paddingTop: '100px' }}>
        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>Cheyenne, WY 82001</div>
          <div className={classes.contactItemNumber}>307-222-4704</div>
        </div>

        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>Minsk, Belarus</div>
          <div className={classes.contactItemNumber}>+375-33-666-28-60</div>
        </div>

        <div className={classes.contactItem}>
          <div style={{ textTransform: 'uppercase' }}>E-mail</div>
          <div className={classes.contactItemNumber}>info@the-view.pro</div>
        </div>
      </div>
    </Page>
  );
};

export default ContactsView;
