import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  icon: {
    width: '12px',
    height: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundImage: 'url(/static/icons/copy.svg)',
    cursor: 'pointer',
    opacity: 0.5,

    '&:hover': {
      opacity: 1,
    },
  },
}));

export default function ({ value = '' }) {
  const classes = useStyles();

  return (
    <div className="relative w-full mb-4">
      <input
        className="w-full"
        style={{
          padding: '5px',
          paddingRight: '36px',
          borderRadius: '5px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          outline: 'none',
          border: 0,
        }}
        value={value}
        readOnly
      />
      <div className={clsx('absolute right-0 top-0 m-3', classes.icon)} />
    </div>
  );
}
